import store from "../../store";

export default {
  install(Vue) {
    // ajax post
    Vue.prototype.$requestEncodeOpen = async seq => {
        const data = await store.dispatch("REQUEST_ENCODE", seq);
        if (data.result === "success") {
            window.open(
            `${process.env.VUE_APP_OBITUARY_URL}/?seq_no=${data.encodeStr}`,
            "_blank",
            "width=640px, height=900, scrollbars=no, resizable=no, toolbars=no, menubar=no"
        );
      }
    };
    Vue.prototype.$requestEncodeOpenE = async e => {
        const data = await store.dispatch("REQUEST_ENCODE", e);
        if (data.result === "success") {
            window.open(
            `${process.env.VUE_APP_OBITUARY_URL}/?event_no=${data.encodeStr}`,
            "_blank",
            "width=640px, height=900, scrollbars=no, resizable=no, toolbars=no, menubar=no"
        );
      }
    };
    Vue.prototype.$requestEncode = async seq => {
        const data = await store.dispatch("REQUEST_ENCODE", seq);
        if (data.result === "success") {
            return `${data.encodeStr}`;
        }
    };
  }
};
