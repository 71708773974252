import {
  requestGetObituary,
  requestGetObituaryUsers,
  requestGetObituarySetting,
  requestGetObituaryReply,
  requestSaveObituary,
  requestUpdateObituaryAlive,
  requestDeleteObituary,
  requestUpdateObituaryComment,
  requestDeleteObituaryComment,
  requestObituaryCommentPwdCheck,
  requestObituarAgree,
} from "../apis/obituary_api";

const state = {
  eventObituary: null,
  eventObituaryOption: null,
  eventUser: null,
  eventFamilies: [],
  eventObituaryComment: [],
  eventAccountList: [],

  eventObitruaryFuneral: null,

  eventUsers: [],
};

const getters = {
  getObituary(state) {
    return state.eventObituary;
  },
  getObituaryUser(state) {
    return state.eventUser;
  },
  getObituaryOption(state) {
    return state.eventObituaryOption;
  },
  getFamilies(state) {
    return state.eventFamilies;
  },
  getObituaryComment(state) {
    return state.eventObituaryComment;
  },
  getObituaryAccountList(state) {
    return state.eventAccountList;
  },

  getObituaryUsers(state) {
    return state.eventUsers;
  },

  getObituaryFuneral(state) {
    return state.eventObitruaryFuneral;
  },
};

const mutations = {
  SET_OBITUARY(state, params) {
    state.eventObituary = params.eventObituary;
    state.eventObituaryOption = params.eventObituaryOption;
    state.eventUser = params.eventUser;
    state.eventUsers = params.eventUsers;
    state.eventFamilies = params.eventFamilies;
    state.eventObituaryComment = params.eventObituaryComment;
    state.eventAccountList = params.eventAccountList;

    state.eventObitruaryFuneral = params.eventObitruaryFuneral;
    state.eventUsers = params.eventUsers;
  },
  SET_OBITUARY_USER(state, params) {
    state.eventUsers = params.eventUsers;
  },
  SET_OBITUARY_SETTING(state, params) {
    state.eventUser = params.eventUser;
    state.eventObituary = params.eventObituary;
    state.eventObituaryOption = params.eventObituaryOption;
  },
  SET_OBITUARY_REPLY(state, params) {
    state.eventUser = params.eventUser;
    state.eventObituary = params.eventObituary;
  },
  SET_OBITUARY_COMMENT(state, params) {
    const findExComment = state.eventObituaryComment.find(
      (comment) =>
        comment.OBITUARY_COMMENT_NO ===
        params.obituaryComment.OBITUARY_COMMENT_NO
    );

    if (findExComment) {
      return;
    }
    state.eventObituaryComment = [
      ...state.eventObituaryComment,
      params.obituaryComment,
    ];
  },
};

const actions = {
  async REQUEST_GET_OBITUARY({ commit }, params) {
    try {
      const result = await requestGetObituary(params);
      if (result) {
        commit("SET_OBITUARY", result);
      }

      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_GET_OBITUARY_USERS({ commit }, params) {
    try {
      const result = await requestGetObituaryUsers(params);

      if (result) {
        commit("SET_OBITUARY_USER", result);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_GET_OBITUARY_SETTING({ commit }, params) {
    try {
      const result = await requestGetObituarySetting(params);

      if (result) {
        commit("SET_OBITUARY_SETTING", result);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_GET_OBITUARY_REPLY({ commit }, params) {
    try {
      const result = await requestGetObituaryReply(params);

      if (result) {
        commit("SET_OBITUARY_REPLY", result);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 부고 저장
  async REQUEST_SAVE_OBITUARY(_, params) {
    try {
      const result = await requestSaveObituary(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 부고 마감 및 마감 취소
  async REQUEST_UPDATE_OBITUARY_ALIVE(_, params) {
    try {
      const result = await requestUpdateObituaryAlive(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  // 부고 삭제
  async REQUEST_DELETE_OBITUARY(_, params) {
    try {
      const result = await requestDeleteObituary(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 추모 메시지 저장
  async REQUEST_UPDATE_OBITUARY_COMMENT({ commit }, params) {
    try {
      const result = await requestUpdateObituaryComment(params);

      if (result) {
        commit("SET_OBITUARY_COMMENT", result);
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 추모 메시지 삭제
  async REQUEST_DELETE_OBITUARY_COMMENT(_, params) {
    try {
      const result = await requestDeleteObituaryComment(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 추모 메시지 비밀번호 확인
  async REQUEST_OBITUARY_COMMENT_PWD_CHECK(_, params) {
    try {
      const result = await requestObituaryCommentPwdCheck(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 개인정보 동의
  async REQUEST_OBITUARY_AGREE(_, params) {
    try {
      const result = await requestObituarAgree(params);

      console.log(result);
      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

export default { mutations, state, actions, getters };
