import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}`,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("v4_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const requestEncode = async (params) => {
  return client
    .get(`/app/encoding?str=${params}`)
    .then((res) => {
        const { data } = res;

        return data;
    })
    .catch((err) => {
      alert("암호화에 실패하였습니다.");
      console.error(err);
    });
};

export {
    requestEncode,
};
