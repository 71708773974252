import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./mixin/globalMixins";
import vuetify from "./plugins/vuetify";
import common from "./assets/js/common";

import VueKakaoSdk from "vue-kakao-sdk";

const apiKey = process.env.VUE_APP_KAKAO_API_KEY;
Vue.use(VueKakaoSdk, { apiKey }); // apiKey 를 반드시
Vue.use(common);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
