import {
  requestEncode,
} from "@/apis/common_api";

const state = {
  errorMessage: "잘못된 요청입니다.",
};

const getters = {
};

const mutations = {
};

const actions = {
  async REQUEST_ENCODE(context, parameter) {
    try {
        const response = await requestEncode(parameter);

        if (response.result === "success") {
            return response
        } else {
            alert(response.message);
            return;
        }
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

export default { mutations, state, actions, getters };
